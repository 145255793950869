<template>
  <g fill="none" fill-rule="evenodd" stroke="#79838E" stroke-width="1.5" transform="translate(1 2)">
    <path stroke-linecap="round" d="M.567 1.5h16.317M.567 8.5h16.317M.567 15.5h16.317" />
    <circle cx="4.5" cy="1.5" r="2.25" fill="#FFF" />
    <circle cx="11.5" cy="8.5" r="2.25" fill="#FFF" />
    <circle cx="7.5" cy="15.5" r="2.25" fill="#FFF" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
